import Layout from 'components/Layout'
import React from 'react'
import AppStateProvider from './../state'
import Hero from '../components/Hero'
import heroImg from '../assets/images/heroImage.svg'
import { Container } from 'components/PageContainer'
import { useState } from 'react'
import './styles/workwithus.scss'
function RootIndex() {
  return (
    <AppStateProvider>
      <Layout title="Careers | SwiftDoc">
        <div className="margin-container">
          <Hero
            heroImg={heroImg}
            title="Join the evolution in online healthcare"
            subTitle="We are always looking for great people to join our team."
            pageName="WORK WITH US"
          />
          <div className="form-container">
            <Container>
              <div className="container-wrapper">
                <div className="preform-text">
                  <p>
                    SwiftDoc strives for excellence in modern healthcare.
                    Telehealth is a rapidly growing sector and it is important
                    to develop skills in this field.
                  </p>
                  <p>
                    SwiftDoc offers the opportunity of flexible working, you can
                    work from anywhere and at anytime.
                  </p>
                  <p>
                    <b>Your Benefits:</b>
                  </p>
                  <ul>
                    <li>It is convenient for both you and your patient</li>
                    <li>Additional income stream</li>
                    <li>You can work from any location</li>
                  </ul>
                  <p>
                    If you are interested in a better work-life balance please
                    contact us by completing the form below.
                  </p>
                </div>

                <div className="form-fields-container">
                  <p>Work with us enquire form</p>
                  <form>
                    <input
                      type="text"
                      name="fullname"
                      placeholder="Full Name (required)"
                    />
                    <input
                      type="text"
                      name="email"
                      placeholder="Email (required)"
                    />
                    <input
                      type="text"
                      name="phone"
                      placeholder="Phone (required)"
                    />
                    <textarea
                      name="qualifications"
                      placeholder="Qualifications (required)"
                    />
                    <input className="submitBtn" type="submit" value="SUBMIT" />
                  </form>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </Layout>
    </AppStateProvider>
  )
}

export default RootIndex
